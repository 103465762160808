import {useRouter} from "next/router";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Logo from '../../01_atoms/Logo/Logo';
import Typography from '@material-ui/core/Typography';
import SocialAccounts from '../../01_atoms/SocialAccounts/SocialAccounts'
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import useSWR from "swr";
import {fetchAPIRequestData} from "../../../api/global";

const useStyles = makeStyles((theme) => ({
      footerLogo: {
        maxWidth: '100%',
      },
      footerTop: {
        backgroundColor: theme.palette.grey[700],
        color: theme.palette.background.paper,
        padding: "2rem 0",
        maxWidth: '100vw',
        overflow: 'hidden',
        "& h2": {
          fontSize: '1.375rem',
          marginTop: '1.5rem',
        },
        "& p": {
          marginTop: "1.5rem",
        },
        "&.footer-search ul": {
          // display: 'flex',
        },
      },

      shortDivider: {
        backgroundColor: theme.palette.success.main,
        maxWidth: '150px',
        margin: '16px 0 16px',
      },
      footerMenu: {
        padding: '0 !important',

        [theme.breakpoints.up("md")]: {
          columnCount: 2,
          // display: 'initial',
        },
        '& a': {
          [theme.breakpoints.up("md")]: {
            // display: 'block',
            width: '100%',
            textAlign: 'left',
            padding: '0 !important',
          },
          padding: '.5rem 0 !important',
        },
      },
      footerList: {
        padding: '0  !important',
        margin: 0,
        "& li": {
          padding: 0,
        },
        "& a": {
          color: 'white',
          padding: '.5rem 0',
          fontSize: '1rem',
        },
        "& p": {
          lineHeight: '2.5',
          padding: 0,
          margin: 0,
        },
      },
      footerBottom: {
        padding: "2rem 0 0",
        "& p": {
          margin: 0,
          fontSize: 12,
        },
        '& svg': {
          color: 'white',
        },
      },
    }),
);
export default function FooterDefault(props) {
  let {menu} = props;
  const classes = useStyles();
  const router = useRouter();
  let isSearch = false;
  if (router?.pathname.includes('/rent/homes-for-rent') || router?.pathname.includes('/buy/homes-for-sale')) {
    // Indicate the page on sales/rent search route.
    isSearch = true;
  }

  const logoReverse = '/assets/eaton-realty-reverse.svg';
  const year = new Date().getFullYear();

  let menu_footer = useSWR(!(menu?.['#items']) ? [`/menu/footer`, {}, {type: 'decouple'}, null, null] : null, ([url, query, opts, pageLimit, pageOffset]) => fetchAPIRequestData(url, query, opts, pageLimit, pageOffset));
  if (!(menu?.['#items']) && menu_footer?.data?.page_data?.['#items']) {
    menu = menu_footer?.data?.page_data;
  }

  return (
      <footer
          className={classNames(classes.footerTop, isSearch ? 'footer-search' : 'footer-search')}>
        <CssBaseline/>
        <Container>
          <Grid container {...(isSearch ? {} : {spacing: 10})}>
            <Grid item {...(isSearch ? {xs: 12} : {xs: 12, md: 5})} >
              <Logo className={classes.footerLogo} alt="Eaton Realty, Inc."
                    name={logoReverse}></Logo>
              <Typography component={`div`} variant="body2" gutterBottom>
                <p>Eaton Realty specializes in the South-East Hillsborough
                  County region of Tampa Bay, specifically FishHawk Ranch.</p>
                <p>In addition to quickly selling listings and representing
                  home buyers, Eaton Realty provides full-service property
                  management
                  for investment clients.
                </p>
              </Typography>
            </Grid>
            <Grid item {...(isSearch ? {xs: 12} : {xs: 12, md: 4})}>
              <Typography style={{color: '#fff'}} variant="h2">Quick
                Links</Typography>
              <Divider className={classes.shortDivider}/>

              <Grid container>
                <Grid item {...(isSearch ? {xs: 12} : {xs: 12, md: 12})}>
                  {menu && menu['#items'] && (
                      <List className={classes.footerMenu}>
                        {
                          Object.entries(menu['#items']).map(([key, item]) => {
                            let uniqueID = key.substring(key.length - 7);
                            return (
                                <ListItem key={`list-${uniqueID}`} button
                                          component="a" href={item.url}>
                                  <ListItemText
                                      primary={item.title}></ListItemText>
                                </ListItem>
                            );
                          })
                        }
                      </List>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item {...(isSearch ? {xs: 12} : {xs: 12, md: 3})}>
              <Typography style={{color: '#fff'}} variant="h2">Contact
                Us</Typography>
              <Divider className={classes.shortDivider}/>

              <List className={classes.footerList}>
                <ListItem>
                  <Link href="tel:18136728022" title={`Call Eaton Realty`}>
                    Call: 813-672-8022
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="mailto:info@eatonrealty.com"
                        title={`Send Eaton Realty an email`}>
                    info@eatonrealty.com
                  </Link>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    14012 Spector Road</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    Lithia, FL 33547-4327
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
        <div className={classes.footerBottom}>
          <Container>
            <Grid container spacing={3}>
              <Grid item {...(isSearch ? {xs: 12} : {xs: 12, md: 6})}>
                <Box>
                  <Typography>
                    All Content © 2002 - {year} Eaton Realty, LLC All Rights
                    Reserved. <Link style={{
                    textDecoration: 'underline',
                    fontWeight: 'normal',
                    color: 'white'
                  }} href="/terms-of-use-privacy">Terms of Use /
                    Privacy</Link></Typography>
                </Box>
              </Grid>
              <Grid item {...(isSearch ? {xs: 12} : {xs: 12, md: 6})}>
                <Box pr={5}>
                  {(isSearch ?
                          <SocialAccounts
                              socialJustify="flex-start"></SocialAccounts>
                          :
                          <SocialAccounts
                              socialJustify="flex-end"></SocialAccounts>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </div>
      </footer>
  );
}
