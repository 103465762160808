import React from 'react';
import {useRouter} from 'next/router';
import {Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Header from "../../../03_organisms/GlobalHeader/Header";
import GlobalFooter from '../../../03_organisms/GlobalFooter/Footer';

const ConditionalWrapper = ({condition, wrapper, children}) =>
    condition == 'contained' ? wrapper(children) : children;

const GlobalLayout = (props) => {
  const {menu, footermenu, blocks, layout, children} = props;
  const router = useRouter();
  return (
      <Fragment key={`GlobalLayout-wrap`}>
        <header>
          <Header menu={menu}></Header>
        </header>
        <ConditionalWrapper condition={layout} wrapper={children => <Container>
          <Box py="2rem">{children}</Box></Container>}
        >
          <div className={`content-main-outer-wrapper`}>
            {Object.keys(blocks).length > 0 &&
            <div className="reactjs-sidebar">
              {
                Object.entries(blocks).map(([key, block]) => {
                  if (block.region == 'react_sidebar' && block.data && block.data.body && block.data.body[0]) {
                    return (<div className={`block-id-${key}`}
                                 key={`react-block-sidebar-${key}`}
                                 dangerouslySetInnerHTML={{__html: block.data.body[0]['#text']}}></div>);
                  }
                  else if (block.region == 'react_sidebar' && block.data) {
                    return (<div className={`block-id-${key}`}
                                 key={`react-block-sidebar-${key}`}
                                 dangerouslySetInnerHTML={{__html: block.data}}></div>);
                  }
                })
              }
            </div>
            }
            <div className="content-main-wrapper">
              <Container>
                {children}
              </Container>
            </div>
            {Object.keys(blocks).length > 0 && (
                <div className="reactjs-footer">
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      {
                        Object.entries(blocks).map(([key, block]) => {
                          if (block.region == 'react_footer' && block.data && block.data.body && block.data.body[0]) {
                            return (<div className={`block-id-${key}`}
                                         key={`react-block-footer-${key}`}
                                         dangerouslySetInnerHTML={{__html: block.data.body[0]['#text']}}></div>);
                          }
                          else if (block.region == 'react_footer' && block.data) {
                            return (<div className={`block-id-${key}`}
                                         key={`react-block-footer-${key}`}
                                         dangerouslySetInnerHTML={{__html: block.data}}></div>);
                          }
                        })
                      }
                    </Grid>
                  </Grid>
                </div>
            )}
          </div>
        </ConditionalWrapper>
        {!router?.pathname?.includes('/rent/homes-for-rent') && !router?.pathname?.includes('/buy/homes-for-sale') &&

        <GlobalFooter menu={footermenu}/>
        }
      </Fragment>
  );
};

GlobalLayout.propTypes = {
  menu: PropTypes.object,
  blocks: PropTypes.object,
};

GlobalLayout.defaultProps = {
  blocks: {},
};

export default GlobalLayout;
