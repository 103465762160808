import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';
import theme from '../../../.storybook/theme';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {visuallyHidden} from '@material-ui/utils';

const useStyles = makeStyles((theme) => ({
      socialLink: {
        backgroundColor: '#3B3B3B',
        padding: '8px 7.5px 8px 8px',
        margin: '.25rem .5rem 2rem 0',
        borderRadius: '100%',
        height: 40,
        width: 40,
        display: 'inline-block',
        color: 'white',
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
        "& svg": {
          color: 'white',
        },
        "& span": {
          color: 'white',
        },
      },
      socialContainer: {
        [theme.breakpoints.down("md")]: {
          padding: '0 !important',
        },
        '& .MuiGrid-container': {
          [theme.breakpoints.down("sm")]: {
            justifyContent: 'flex-start',
          },
        },
      }
    }),
);


export default function SocialAccounts(props) {
  const {socialJustify} = props;
  const classes = useStyles(props);
  return (

      <div>
        <Container className={classes.socialContainer}>
          <Grid container
                direction="row"
                justifyContent={socialJustify}>
            <Grid item>
              <Link target="_blank" className={classes.socialLink}
                    href="https://www.facebook.com/eatonrealty">
                <Typography variant="srOnly">Follow Eaton Realty on
                  Facebook</Typography>
                <FacebookIcon/>
              </Link>

              <Link target="_blank" className={classes.socialLink}
                    href="https://twitter.com/eatonrealty">
                <TwitterIcon/>
                <Typography variant="srOnly">Connect with Eaton Realty on
                  Twitter</Typography>

              </Link>

              <Link target="_blank" className={classes.socialLink}
                    href="https://www.pinterest.com/eatonrealtyfl/">
                <PinterestIcon/>
                <Typography variant="srOnly">Follow Eaton Realty on
                  Pinterest</Typography>

              </Link>

              <Link target="_blank" className={classes.socialLink}
                    href="https://www.linkedin.com/company/eatonrealty">
                <LinkedInIcon/>
                <Typography variant="srOnly">Connect Eaton Realty on
                  LinkedIn</Typography>

              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>
  );
}
