import PropTypes from 'prop-types';
import Head from 'next/head';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CustomMuiButton from '../CustomMuiButton';
import Custom404 from '../../../pages/404';
import Custom403 from '../../../pages/403';

const ErrorMessage = ({statusCode}) => {
  statusCode = statusCode || 500;
  return (

      <Container>
        <Box className="error-message" textAlign="center" py={10}>
          {statusCode == 404 &&
          <>
            <Head key="head">
              <title key="title">Page not found | Eaton Realty</title>
            </Head>
            <Custom404/>
          </>
          }
          {statusCode == 403 &&
          <Custom403/>
          }
          {statusCode != 404 && statusCode != 403 &&
          <div>
            <Typography variant="h1" component="h1">
              Oops, <strong>{statusCode}</strong> error.
            </Typography>
            <Box className="error-message" textAlign="center" py={6}>
              <CustomMuiButton href="/" label="Back to"
                               stronglabel="Homepage"></CustomMuiButton>
            </Box>
          </div>
          }
        </Box>
      </Container>
  );
}

ErrorMessage.propTypes = {
  statusCode: PropTypes.number,
};

export default ErrorMessage;
