// "use strict";
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  // This has to vary for multiple buttons on the same page, for example.
  // In this case class will be non-deterministic, e.g. 'jss-1'
  IncBtn: {
    color: props => props.txtColor ?? props.txtColor,
  },
  // This is common for all, so we can have standard CSS class.
  '@global': {
    '.IncBtn': {
      "& strong": {
        fontWeight: 800,
        whiteSpace: 'break-spaces',
      },
      '& span': {
        whiteSpace: 'break-spaces',

      },
      '& a:hover': {
        textDecoration: 'none',
      },
    },
  },
}));

const ConditionalWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;

function CustomMuiButton(props) {
  const classes = useStyles(props);

  let {
    block,
    variant,
    color,
    size,
    label,
    stronglabel,
    href,
    hreftarget,
    fullWidth,
    margin,
    marginUnit,
    justifyContent,
    onClick
  } = props;
  label = label.indexOf('<') == 0 ? label : `<span>${label}</span>`;

  let mt, mr, mb, ml, unit;
  if (margin) {
    unit = marginUnit || 'px';
    ([mt, mr, mb, ml] = margin);
  }

  if (block) {
    const block_data = props?.block?.data || '';
    const data = JSON.parse(block_data);
    if (data) {
      justifyContent = data.justifyContent;
    }
  }

  return (
      <div className={`IncBtn`}>
        <ConditionalWrapper condition={margin || justifyContent}
                            wrapper={children =>
                                <Box display={`flex`}
                                     justifyContent={justifyContent}
                                     mt={`${mt}${unit}`} mr={`${mr}${unit}`}
                                     mb={`${mb}${unit}`}
                                     ml={`${ml}${unit}`}>{children}</Box>}>
          <ConditionalWrapper condition={href}
                              wrapper={children => <a href={href}
                                                      target={hreftarget}>{children}</a>}>
            <Button className={`${classes.IncBtn}`}
                    disableElevation={true}
                    variant={variant} fullWidth={fullWidth}
                    size={size} color={color}
                    onClick={onClick}
            >
              {parse(label)} {stronglabel ?
                <strong>&nbsp;{stronglabel}</strong> : null}
            </Button>
          </ConditionalWrapper>
        </ConditionalWrapper>
      </div>
  );
}

CustomMuiButton.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * Is this the principal call to action on the page?
   */
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  /**
   * What background color to use
   */
  color: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Where to open the link? (new tab, same, etc.)
   */
  hreftarget: PropTypes.string,
  /**
   * CustomButton contents
   */
  stronglabel: PropTypes.string,
  /**
   * Optional override for button text color (CSS color string)
   */
  txtColor: PropTypes.string,
  /**
   * CustomButton contents
   */
  label: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

CustomMuiButton.defaultProps = {
  color: 'primary',
  variant: 'contained',
  size: 'medium',
  label: 'Primary Button',
  // href: '#',
  // hreftarget: '_self',
  // txtColor: '#ff2322'
};

export default CustomMuiButton;
