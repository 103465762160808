// @see
// https://github.com/DrupalizeMe/react-and-drupal-examples/tree/master/react-decoupled
// @see https://dev.acquia.com/blog/decoupled-drupal-authentication-oauth-20
// @see
// https://drupalize.me/tutorial/use-fetch-and-oauth-make-authenticated-requests?p=3253
import {Cookies} from "react-cookie";
import cookie from "cookie";
import {
  drupalUserAccountActions,
  getPageDataAuthenticated
} from "../api/global";

// import {useContext} from 'react';
// import CurrentPageContext from "../components/context/CurrentPageData";

/**
 * @file
 *
 * Wrapper around fetch(), and OAuth access token handling operations.
 *
 * To use import getAuthClient, and initialize a client:
 * const auth = getAuthClient(optionalConfig);
 */

const refreshPromises = [];
export const AGENT_STORAGE_VAR = 'gmuser';
export const USER_STORAGE_VAR = 'd-eat-lead';
export const FAV_HOMES_STORAGE_VAR = 'd-eat-favs';
export const SESSION_TOKEN = 'drupal-oauth-token';

/**
 * OAuth client factory.
 *
 * @param {object} config
 *
 * @returns {object}
 *   Returns an object of functions with $config injected.
 */
export function getAuthClient(config = {}, api = false) {
  api = api || false;
  const cookiesLib = new Cookies();

  const defaultUserConfig = {
    // Base URL of your Drupal site.
    base: process.env.NEXT_PUBLIC_BACKEND_URL,
    // Name to use when storing the token in localStorage.
    token_name: SESSION_TOKEN,
    // OAuth client ID - get from Drupal.
    client_id: process.env.LEAD_CLIENT_ID,
    // OAuth client secret - set in Drupal.
    client_secret: process.env.LEAD_CLIENT_SECRET,
    // Drupal user role related to this OAuth client.
    scope: process.env.LEAD_CLIENT_SCOPE,
    // Margin of time before the current token expires that we should force a
    // token refresh.
    expire_margin: 0,
  };
  const defaultApiConfig = {
    // Base URL of your Drupal site.
    base: process.env.NEXT_PUBLIC_BACKEND_URL,
    // Name to use when storing the token in localStorage.
    token_name: SESSION_TOKEN,
    // OAuth client ID - get from Drupal.
    client_id: process.env.CLIENT_ID,
    // OAuth client secret - set in Drupal.
    client_secret: process.env.CLIENT_SECRET,
    // Drupal user role related to this OAuth client.
    scope: process.env.CLIENT_SCOPE,
    // Margin of time before the current token expires that we should force a
    // token refresh.
    expire_margin: 0,
  };
  if (api) {
    config = {...defaultApiConfig, ...config};
  }
  else {
    config = {...defaultUserConfig, ...config};
  }

  /**
   * Register a new user account.
   *
   * @param {string} mail
   * @param {string} password
   */
  async function register(mail, password) {
    let formData = new FormData();
    formData.append('client_id', config.client_id);
    formData.append('client_secret', config.client_secret);
    formData.append('scope', config.scope);
    if (!api) {
      formData.append('grant_type', 'password');
      formData.append('username', username);
      formData.append('password', password);
    }
    else {
      formData.append('grant_type', 'client_credentials');
    }

    try {
      const response = await fetch(`${config.base}/oauth/token`, {
        method: 'post',
        headers: new Headers({
          // 'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
        }),
        body: formData,
      });
      const data = await response.json();
      if (data.error) {
        console.warn('Error retrieving token', data);
        return Promise.reject(new Error(`Error retrieving OAuth token: ${data.error}`));
      }
      setLocalStorage('user', {name: username}, true);
      return saveToken(data);
    }
    catch (err) {
      console.warn('API got an error', err);
      return Promise.reject(new Error(`API error: ${err}`));
    }
  }

  /**
   * Update Drupal user account data.
   *
   * @param params
   */
  async function userUpdate(params) {

    let fetchOpts = {
      url: `/users/${params.uuid}`,
      opts: {type: 'api', method: 'patch', uuid: params.uuid},
      params: params.params,
    };
    let token = params.auth || false;

    if (!params?.uuid) {
      let usrCookie = getLocalStorage('user');
      if (usrCookie?.id) {
        params.uuid = usrCookie.id;
      }
      else {
        return Promise.reject(new Error(`Incorrect submission data: missing user ID`));
      }
    }

    try {
      let tok = token ? token : await isLoggedIn();
      if (tok && tok !== 'revoked') {
        fetchOpts.opts.auth = token || tok?.access_token;
        let res = await drupalUserAccountActions(fetchOpts.url, fetchOpts.opts, fetchOpts.params);

        if (res?.page_data?.id) {
          setLocalStorage('user', {
            id: res?.page_data?.id,
            fullName: res?.page_data?.fullName,
            mail: res?.page_data?.mail,
            name: res?.page_data?.name,
          });

          if (params.params.field_fav_homes) {
            setLocalStorage(FAV_HOMES_STORAGE_VAR, res?.page_data?.favHomes, true);
          }

          return Promise.resolve(res);
        }

        if (res?.errors) {
          return Promise.reject(res);
        }
      }
      return Promise.reject('User UPDATE:: Authentication issue.');
    }
    catch (err) {
      console.error('API got an error in userUpdate()', err);
      return Promise.reject(err);
    }
  }

  /**
   * Exchange a username & password for an OAuth token.
   *
   * @param params
   */
  async function cancelUser(params) {
    if (!params) {
      return Promise.reject({message: 'User cancellation request: unhandled error. Not enough access.'});
    }

    let fetchOpts = {
      url: `/users/${params.uuid}`,
      opts: {type: 'api', method: 'delete', uuid: params.uuid},
      params: params.params,
    };
    let token = params.auth || false;

    if (!params?.uuid) {
      let usrCookie = getLocalStorage('user');
      if (usrCookie?.id) {
        params.uuid = usrCookie.id;
      }
      else {
        return Promise.reject(new Error(`Incorrect submission data: missing user ID`));
      }
    }

    try {
      let tok = token ? token : await isLoggedIn();
      if (tok && tok !== 'revoked') {
        fetchOpts.opts.auth = token || tok?.access_token;
        let res = await drupalUserAccountActions(fetchOpts.url, fetchOpts.opts, fetchOpts.params);
        if (res?.statusCode == 204) {
          logout().then(() => {
            // router.push('/');
          });
          return Promise.resolve({success: true});
        }

        if (res?.errors) {
          console.error('Error cancelling user account -- AUTH userCancel ()', res);
          return Promise.reject(res);
        }
      }
      return Promise.reject({message: 'User cancellation request: unhandled error. Try again.'});
    }
    catch (err) {
      console.error('API got an error in userCancel()', err);
      return Promise.reject(err);
    }
  }

  /**
   * Exchange a username & password for an OAuth token.
   *
   * @param {string} username
   * @param {string} password
   * @param {boolean} saveFavs
   */
  async function login(username, password, saveFavs) {
    let formData = new FormData();
    saveFavs = saveFavs || false;
    formData.append('client_id', config.client_id);
    formData.append('client_secret', config.client_secret);
    formData.append('scope', config.scope);

    if (!api) {
      formData.append('grant_type', 'password');
      formData.append('username', username);
      formData.append('password', password);
    }
    else {
      formData.append('grant_type', 'client_credentials');
    }

    try {
      const response = await fetch(`${config.base}/oauth/token`, {
        method: 'post',
        headers: new Headers({
          // 'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
        }),
        body: formData,
      });
      const data = await response.json();
      if (data.error) {
        console.warn('Error retrieving token', data);
        return Promise.reject(data);
      }
      setLocalStorage('user', {name: username}, true);

      // Proceed with retrieving essential user info (we need UUID at least):
      getUserAfterLogin(username, data?.access_token, saveFavs);
      return saveToken(data);
    }
    catch (err) {
      console.error('Login API got an error', err, response);
      return Promise.reject({message: 'Network error. Try again.'});
    }
  }

  async function getUserAfterLogin(usrname, tok, saveFavs) {
    saveFavs = saveFavs || false;

    if (usrname && tok) {
      let user_api_data, homes = [];
      let usrCookie = getLocalStorage('user');

      if (!usrCookie?.id) {
        getPageDataAuthenticated('/users', {'filter[name][value]': usrname}, {
          type: 'api',
          auth: tok,
        })
          .then(res => {
            if (res?.page_data && res?.statusCode == 200) {
              user_api_data = res.page_data[0] || res.page_data;
              // Store fav homes for further sync into Drupal (it's merging
              // with any local storage data, if exists):
              homes = user_api_data?.favHomes || [];
              setLocalStorage(FAV_HOMES_STORAGE_VAR, homes);
            }
            else if (res?.statusCode > 400) {
              console.warn('getUserAfterLogin promise resolved: Looks like your session has expired.. Please log in again to view your account');
            }

            if (user_api_data) {
              usrCookie = {
                id: user_api_data?.id,
                fullName: user_api_data?.fullName,
                mail: user_api_data?.mail,
                name: user_api_data?.name,
              };
              setLocalStorage('user', usrCookie);
              updateFavListings(usrCookie, tok);
            }
          })
          .catch((error) => {
            console.error('getUserAfterLogin error', error);
          });
      }
      else if (usrCookie?.id /*&& saveFavs*/) {
        updateFavListings(usrCookie, tok);
      }
    }
  }

  async function updateFavListings(usr, tok, new_homes) {
    let fav_store = getLocalStorage(FAV_HOMES_STORAGE_VAR);
    if (!usr) {
      usr = getLocalStorage('user');
    }

    if (usr?.id && tok) {
      let homes = usr?.favHomes || [];
      if (new_homes) {
        homes = new_homes;
      }
      else if (fav_store?.length > 0) {
        // First, sync browser storage with user account data in drupal:
        fav_store.map((h) => {
          if (!isNaN(Number.parseInt(h)) && !homes.includes(h)) {
            homes.push(h);
          }
        });
      }

      let updateOpts = {
        uuid: usr.id, // required
        auth: tok,
        params: {field_fav_homes: homes.filter(item => item)},
      };

      (async () => {
        try {
          let result = userUpdate(updateOpts)
            .then(res => {
              return Promise.resolve(res);
            })
            .catch((error) => {
              console.error('updateFavListings error', error);
              return Promise.reject(error);
            });
        }
        catch (e) {
          console.error("updateFavListings: favs sync error", e);
        }
      })();
    }
  }

  /**
   * Delete the stored OAuth token, effectively ending the user's session.
   */
  function logout() {
    if (typeof window !== "undefined") {
      localStorage.removeItem(SESSION_TOKEN);
      localStorage.removeItem(USER_STORAGE_VAR);
      // @todo: clean up FAVS local storage, too? I think we do need to:
      localStorage.removeItem(FAV_HOMES_STORAGE_VAR);
      localStorage.removeItem('favs-expire');
    }
    const opts = {
      path: "/",
      sameSite: true,
    };
    // Cookies need to have both path and domain appended to them to be removed.
    cookiesLib.remove(SESSION_TOKEN, opts);
    cookiesLib.remove(USER_STORAGE_VAR, opts);

    return Promise.resolve(true);
  }

  /**
   * Wrapper for fetch() that will attempt to add a Bearer token if present.
   *
   * If there's a valid token, or one can be obtained via a refresh token, then
   * add it to the request headers. If not, issue the request without adding an
   * Authorization header.
   *
   * @param {string} url URL to fetch.
   * @param {object} options Options for fetch().
   */
  async function fetchWithAuthentication(url, options) {
    if (!options.headers.get('Authorization')) {
      const oauth_token = await token();
      if (oauth_token) {
        options.headers.append('Authorization', `Bearer ${oauth_token.access_token}`);
      }
    }

    return fetch(`${config.base}${url}`, options);
  }

  /**
   * Get the current OAuth token if there is one.
   *
   * Get the OAuth token form localStorage, and refresh it if necessary using
   * the included refresh_token.
   *
   * @returns {Promise}
   *   Returns a Promise that resolves to the current token, or false.
   */
  async function token() {
    let token;
    token = getLocalStorage('token');

    if (!token) {
      return Promise.reject(false);
    }

    const {expires_at, refresh_token} = token;
    if (expires_at - config.expire_margin < Date.now() / 1000) {
      const refresh = await refreshToken(refresh_token);
      if (refresh?.error && (refresh?.hint?.includes('revoked') || refresh?.message?.includes('token is invalid'))) {
        return Promise.reject('revoked');
      }
      return refresh;
    }
    return Promise.resolve(token);
  }

  /**
   * Request a new token using a refresh_token.
   *
   * This function is smart about reusing requests for a refresh token. So it is
   * safe to call it multiple times in succession without having to worry about
   * whether a previous request is still processing.
   */
  function refreshToken(refresh_token) {
    if (refreshPromises[refresh_token]) {
      return refreshPromises[refresh_token];
    }

    // Note that the data in the request is different when getting a new token
    // via a refresh_token. grant_type = refresh_token, and do NOT include the
    // scope parameter in the request as it'll cause issues if you do.
    let formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', config.client_id);
    formData.append('client_secret', config.client_secret);
    formData.append('refresh_token', refresh_token);

    return (refreshPromises[refresh_token] = fetch(`${config.base}/oauth/token`, {
        method: 'post',
        headers: new Headers({
          'Accept': 'application/json',
        }),
        body: formData,
      })
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          delete refreshPromises[refresh_token];

          if (data.error) {
            console.warn('Error refreshing token', data);
            return data;
            // return Promise.reject(data);
          }
          return saveToken(data);
        })
        .catch(err => {
          delete refreshPromises[refresh_token];
          return Promise.reject(err);
        })
    );
  }

  /**
   * Store an OAuth token retrieved from Drupal in localStorage.
   *
   * @param {object} data
   * @returns {object}
   *   Returns the token with an additional expires_at property added.
   */
  function saveToken(data) {
    let token = Object.assign({}, data); // Make a copy of data object.
    token.date = Math.floor(Date.now() / 1000);
    token.expires_at = token.date + token.expires_in;
    setLocalStorage('token', token);
    return token;
  }

  /**
   * Check if the current user is logged in or not.
   *
   * @returns {Promise}
   */
  async function isLoggedIn() {
    const oauth_token = await token();
    if (oauth_token && oauth_token == 'revoked') {
      return Promise.reject('revoked');
    }
    else if (oauth_token) {
      return Promise.resolve(oauth_token);
    }
    return Promise.reject(false);
  }

  /**
   * Run a query to /oauth/debug and output the results to the console.
   */
  function debug() {
    const headers = new Headers({
      Accept: 'application/vnd.api+json',
    });

    fetchWithAuthentication('/oauth/debug?_format=json', {headers})
      .then((response) => response.json())
      .then((data) => {
      });
  }

  function getLocalStorage(type, getcookie, decode) {
    let store;
    getcookie = getcookie || false;
    decode = decode || false;
    type = type || 'user';
    let store_item = type;
    if (type == 'user') {
      store_item = USER_STORAGE_VAR;
      getcookie = true;
      decode = true;
    }
    else if (type == 'agent') {
      store_item = AGENT_STORAGE_VAR;
    }
    else if (type == 'token') {
      store_item = config.token_name;
      getcookie = true;
      decode = true;
    }

    try {
      if (getcookie) {
        store = retrieveCookie(store_item, decode);
      }
      else if (typeof window !== "undefined") {
        let val = localStorage.getItem(store_item);
        if (decode) {
          store = (val) ? atob(val) : val;
        }
        store = val ? JSON.parse(val) : false;
      }
      // Remove GMUSER var after 30 days. If it's defined on the user account,
      // it will be set again upon logging back in.
      if (store_item == 'gmuser' && store?.expire) {
        let curr = Math.floor(new Date().getTime() / 1000);
        if (curr - store.expire > 0) {
          if (typeof window !== "undefined") {
            localStorage.removeItem(store_item);
            return false;
          }
        }
      }
      return store;
    }
    catch (e) {
      console.warn('getLocalStorage ERR: ', e);
    }
  }

  function setLocalStorage(type, data, rewrite, setcookie, encode, opts) {
    try {
      let store;
      setcookie = setcookie || false;
      encode = encode || false;
      opts = opts || {
        path: "/",
        maxAge: 3600, // Expires after 1hr
        sameSite: true,
      };
      rewrite = rewrite || false;
      type = type || 'user';
      let store_item = type;
      if (type == 'user') {
        store_item = USER_STORAGE_VAR;
        setcookie = true;
        encode = true;
      }
      else if (type == 'agent') {
        store_item = AGENT_STORAGE_VAR;
      }
      else if (type == 'token') {
        store_item = config.token_name;
        setcookie = true;
        encode = true;
      }

      if (setcookie) {
        store = retrieveCookie(store_item, encode);
      }
      else if (typeof window !== "undefined") {
        store = getLocalStorage(store_item, setcookie, encode);
      }

      if (store && !rewrite) {
        // It can be an array, then merging/spread should be checked
        // differently:
        if (Array.isArray(store)) {
          data = [...store, ...data];
          data = data.filter((v, i, a) => a.indexOf(v) == i);
        }
        // It can be an object:
        else if (typeof store === 'object' && store !== null) {
          data = {...store, ...data};
        }
        // Or it can be any other simple variable: string, int, etc.
        else {
          data = store;
        }
      }

      if (setcookie) {
        storeCookie(store_item, data, opts, encode);
      }
      else if (typeof window !== "undefined") {
        localStorage.setItem(store_item, JSON.stringify(data));
      }
    }
    catch (e) {
      console.warn('setLocalStorage ERR: ', e);
    }
  }

  function storeCookie(name, data, opts, encode) {
    encode = encode || true;
    if (name && data) {
      let d = JSON.stringify(data);
      if (encode) {
        d = btoa(d);
      }
      cookiesLib.set(name, d, opts);
    }
  }

  function retrieveCookie(name, decode) {
    decode = decode || true;
    let d;
    if (name) {
      d = cookiesLib.get(name);
      if (decode && d) {
        d = atob(d);
      }
      d = d ? JSON.parse(d) : d;
    }
    return d;
  }

  function parseCookies(req) {
    return cookie.parse(req ? req.headers.cookie || "" : document.cookie);
  }

  return {
    debug, login, register, userUpdate, logout, isLoggedIn, updateFavListings,
    getLocalStorage, setLocalStorage, parseCookies, storeCookie, retrieveCookie,
    fetchWithAuthentication, token, refreshToken, cancelUser
  };
}
