import {
  createTheme
} from '@material-ui/core/styles';

//import '@fontsource/raleway/index.css';
//import '@fontsource/raleway/900.css';
//import '@fontsource/lato/index.css';
//import '@fontsource/lato/300.css';
//import '@fontsource/lato/700.css';
//import '@fontsource/lato/900.css';

const defaultTheme = createTheme();

const theme = {
  themeName: 'EatonRealty',
  palette: {
    primary: {
      main: '#00235D',
      contrastText: '#ffffff',
      light: '#38578a',
    },
    secondary: {
      main: '#847251',
      dark: '#AC9C81',
      contrastText: '#ffffff',
    },
    text: {
      main: '#f44336',
      secondary: '#8D8D8D',
      disabled: '#333333',
    },
    error: {
      main: "#D93025",
      contrastText: "#000000",
    },
    success: {
      main: '#6EC7C1',
      light: '#42B5B5',
      lighter: '#FEF0D3',
    },
    warning: {
      main: '#FED36B',
      light: '#FAE4B4',
      dark: '#FDD26A',
    },
    info: {
      main: '#326398',
      light: '#0071B3',
    },
    grey: {
      // 500: '#FAFAFA',
      100: '#f0f0f0',
      200: '#F4F4F4',
      300: '#D9D9D9',
      400: '#D8DADA',
      500: '#EEEEEE',
      600: '#5A646F',
      700: '#3f4553',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    body: {
      fontSize: 4,
      color: '#f0f0f0',
    }
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    h1: {
      fontSize: "3.125rem",
      fontFamily: "'Raleway'",
      fontWeight: 300,
      textTransform: "uppercase",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '2.75rem',
      },
    },
    h2: {
      fontSize: "2.5rem",
      fontFamily: "'Raleway'",
      fontWeight: 300,
      textTransform: "uppercase",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '2.5rem',
      },
    },
    h3: {
      fontSize: "2.25rem",
      fontFamily: "'Raleway'",
      fontWeight: 300,
      textTransform: "uppercase",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '2rem',
      },
    },
    h4: {
      fontSize: "2rem",
      fontFamily: "'Raleway'",
      fontWeight: 300,
      textTransform: "uppercase",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '1.85rem',
      },
    },
    h5: {
      fontSize: "1.5rem",
      fontFamily: "'Raleway'",
      fontWeight: 300,
      textTransform: "uppercase",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: '1.75rem',
      },
    },
    h6: {
      fontSize: "1.125rem",
      fontFamily: "'Raleway'",
      textTransform: "uppercase",
    },
    body1: {
      fontSize: "1.125rem",
      marginBottom: "1rem",
      fontWeight: 400,
      "& a": {
        fontWeight: 700,
      },
    },
    body2: {
      fontSize: "1.125rem",
    },
  },
  shape: {
    borderRadius: 0,
  },

  overrides: {
    MuiPagination: {
      ul: {
        justifyContent: 'center',
        // justifyContent: 'flex-start !important',
        [defaultTheme.breakpoints.down("sm")]: {
          justifyContent: 'space-between !important',
        },
        "& li ": {
          listStyleType: 'none !important',
        },
      },
    },
    MuiPaginationItem: {
      root: {
        color: '#0071B3',
        fontSize: 24,
        selected: {
          backgroundColor: 'transparent !important',
        },
        "&$outlined": {
          border: 'none',
        },
        outlinedPrimary: {
          border: 'none !important',
          backgroundColor: 'transparent !important',
          '&:hover': {
            color: '#00235D',
          },
        },
        "&$selected": {
          "backgroundColor": "transparent !important",
          color: '#00235D !important',
          border: 'none !important',
        }
      },

    },
    MuiCssBaseline: {
      '@global': {
        body: {
          maxWidth: '100%',
          overflowX: 'hidden !important',
          backgroundColor: '#ffffff',
          width: '100vw',
        },
        '.homepageHero': {
          backgroundImage: `url(${'/assets/eaton-homepage-bg.jpg'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: 450,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          [defaultTheme.breakpoints.down("md")]: {
            minHeight: 350,
          },
          [defaultTheme.breakpoints.down("sm")]: {
            minHeight: 200,
          },
        },
        '.text-block': {
          margin: '5rem 0',
        },
        '.sr-only': {
          position: 'absolute',
          width: '1px',
          height: '1px',
          padding: 0,
          margin: '-1px',
          overflow: 'hidden',
          clip: 'rect(0,0,0,0)',
          border: 0,
        },
        code: {
          backgroundColor: "#f1f1f1",
          marginTop: '1rem',
          padding: '1rem',
          fontFamily: "'Open Sans', sans-serif",
          display: 'block',
        },
        '.infinite-scroll-component::-webkit-scrollbar': {
          display: 'none !important',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        },
        '#__next': {
          maxWidth: '100vw',
          overflowX: 'hidden',
        },
        '.gm-style .gm-style-iw-d': {
          padding: '2rem 1.5rem 1rem 0 !important',
          overflow: 'visible !important',
        },
        '.gm-ui-hover-effect': {
          right: '0.1rem !important',
          top: '0 !important',
        },
        lato: {
          fontFamily: "'Open Sans', sans-serif",
        },
        '.box-shadow': {
          boxShadow: '0 0 1px 1px white inset, 0 23px 21px -33px #000',
          backgroundColor: 'white !important',
          padding: '1rem 1rem 0',
          margin: '2.1rem 0',
        },
        '.responsive-iframe-container iframe': {
          [defaultTheme.breakpoints.down("md")]: {

            width: '100% !important',
            height: 'auto',
          },
        },
        '.responsive-iframe-container': {
          [defaultTheme.breakpoints.down("md")]: {
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            paddingTop: '56.25%',
            '& iframe': {
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
            },
          },
        },
        '.spacer-small': {
          height: 50,
          [defaultTheme.breakpoints.down("md")]: {
            height: 25,
          }

        },
        '.spacer-medium': {
          height: 100,
          [defaultTheme.breakpoints.down("md")]: {
            height: 30,
          }

        },
        '.spacer-large': {
          height: 150,
          [defaultTheme.breakpoints.down("md")]: {
            height: 50,
          }
        },
        '.clickable-cta-tile .MuiGrid-root > .MuiGrid-root > .MuiBox-root': {
          position: 'relative',
          '& h3, & span': {
            // color: '#3b3b3b !important',
          },
          '&:hover': {
            backgroundColor: '#309898 !important',
            '& h3, & span': {
              color: '#3b3b3b !important',
            },
          },
        },
        '.clickable-cta-tile a': {
          '&:after': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          },
        },
        ".IncCtaTxt ul": {
          textAlign: 'left',
        },
        ".IncCtaTxt ul li": {
          padding: '0 0 1rem'
        },
        ".content-css span, .content-css p": {
          fontSize: 18,
          margin: 0,
          [defaultTheme.breakpoints.down("md")]: {
            fontSize: 16,
          },
        },
        '.modalClose': {
          position: 'absolute',
          top: '.5rem',
          right: '.5rem',
          '& svg': {
            width: '2rem',
            height: '2rem',
            fontSize: '3rem',
            color: defaultTheme.palette.grey[900],
          }
        },
        '.modalContent': {
          background: 'white',
          width: 500,
          minHeight: 200,
          maxWidth: '95vw',
          left: '50%',
          top: '50%',
          position: 'fixed',
          transform: 'translate(-50%, -50%)',
          padding: '1rem 2.5rem 1rem 1rem',
          border: 'solid 2px black',
          backgroundColor: '#f4f4f4',
        },
        '.modalContentFull': {
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          backgroundColor: defaultTheme.palette.background.paper,
          padding: '5vh 10vw',
          overflow: 'auto',
          '& h4': {
            maxWidth: 'calc(100vw - 9rem)',
          },
        },
        ".expandAnchor": {
          fontSize: 12,
          textDecoration: 'underline',
        }
      },
    },
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      containedSizeLarge: {
        fontSize: 18,
        padding: '.2rem 1.25rem',
      },
      containedPrimary: {
        backgroundColor: '#FED36B',
        border: 'solid 4px #FED36B',
        color: '#000000',
        "&:hover": {
          backgroundColor: '#ecc159 !important',
          border: 'solid 4px #ecc159',
        },
      },
      containedSecondary: {
        backgroundColor: '#3F4553',
        border: 'solid 4px #3F4553',
        color: '#ffffff',
        "&:hover": {
          backgroundColor: '#333333',
        },
      },
      contained: {
        backgroundColor: '#42B5B5',
        border: 'solid 4px #42B5B5',
        color: '#000000',
        "&:hover": {
          backgroundColor: '#5eb5ae',
        },
      },
      outlined: {
        border: 'solid 4px #42B5B5 !important',
        color: '#000000',
        "&:hover": {
          border: 'solid 4px #5eb5ae !important',
          backgroundColor: '#5eb5ae',
        },
      },
      outlinedPrimary: {
        border: 'solid 4px #FED36B !important',
        color: '#000000',
        "&:hover": {
          border: 'solid 4px #FED36B !important',
          backgroundColor: '#FED36B',
        },
      },
      outlinedSecondary: {
        border: 'solid 4px #3F4553 !important',
        color: '#3F4553',
        "&:hover": {
          border: 'solid 4px #3F4553 !important',
          backgroundColor: '#3F4553 !important',
          color: '#ffffff',
        },
      },
      outlinedSizeLarge: {
        padding: '.2rem 1.25rem !important',
        fontSize: 18,
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
      }
    },
    MuiTab: {
      root: {
        fontSize: 18,
        marginRight: 8,
        [defaultTheme.breakpoints.up("md")]: {
          fontSize: 22,
          marginRight: 40,
          maxWidth: 1000,
        },
        whiteSpace: 'inherit',
        overflow: 'visible',
        fontWeight: 300,
        color: '#5c5c5c !important',
        opacity: '1 !important',
        backgroundColor: '#dddddd',
        padding: '.5rem 2rem',
        margin: '0 !important',
        "& :hover": {
          fontWeight: 600,
        },
        "& span": {
          alignItems: 'flex-start',
        },
        "&$selected": {
          fontWeight: 800,
          backgroundColor: "#eee",
          "& :hover": {
            fontWeight: 800,
          }
        },

      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: '#fafafa',
        margin: '1rem 0',
      },
    },
    MuiAccordionDetails: {
      root: {
        color: '#000000',
      },
    },
    MuiTablePagination: {
      input: {
        marginBottom: 0,
      }
    }
  },
};

export const themeExtender = createTheme(theme);

export default theme;
